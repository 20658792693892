import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';


import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { FormGroupAngularModule } from 'rev-shared/ui/formGroup/FormGroupAngular.Module';
import { TextInputAngularModule } from 'rev-shared/ui/text-input/TextInputAngular.Module';
import { VbUiTooltipModule } from 'rev-shared/ui/tooltip/VbUiTooltip.Module';
import { ValidationAngularModule } from 'rev-shared/ui/validation/ValidationAngular.Module';
import { ValidationModule } from 'rev-shared/util/directives/form/validation/Validation.AngularModule';

import { TemplateMenuComponent } from './TemplateMenu.Component';
import { TemplatePickerComponent } from './TemplatePicker.Component';

const declarations = [
	TemplateMenuComponent,
	TemplatePickerComponent
];

@NgModule({
	declarations,
	exports: declarations,
	imports: [
		BsDropdownModule,
		ButtonsAngularModule,
		CommonModule,
		FormGroupAngularModule,
		FormsModule,
		TextInputAngularModule,
		TranslateModule,
		TypeaheadModule,
		ValidationAngularModule,
		ValidationModule,
		VbUiTooltipModule
	]
})
export class TemplatesMenuAngularModule {}
