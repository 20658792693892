import { Directive, Input, OnChanges, OnDestroy, SimpleChanges, HostBinding, ChangeDetectorRef } from '@angular/core';

import { MainHeaderPositionService } from './MainHeaderPosition.Service';

@Directive({
	selector: '[transitionBackground]'
})
export class TransitionBackgroundDirective implements OnDestroy, OnChanges {
	@Input('transitionBackground') public isEnabled: boolean;
	@Input('transitionBackgroundClass') public bgClass: string;

	@HostBinding('class') public classes: string = '';

	private handler: () => void;
	private isBackgroundApplied: boolean;

	constructor(
		private ref: ChangeDetectorRef,
		private MainHeaderPosition: MainHeaderPositionService
	) {}

	public ngOnChanges(changes: SimpleChanges): void {
		if (!changes.isEnabled) {
			return;
		}
		if (this.isEnabled) {
			this.tryAddScrollHandler();
		} else {
			this.tryRemoveScrollHandler();
		}
		this.updateBgClass();
	}

	public ngOnDestroy(): void {
		this.tryRemoveScrollHandler();
	}

	public tryAddScrollHandler(): void {
		if (!this.handler) {
			this.handler = () => this.updateBgClass();
			window.addEventListener(
				'scroll',
				this.handler,
				true
			);
		}
	}

	public tryRemoveScrollHandler(): void {
		if (this.handler) {
			window.removeEventListener(
				'scroll',
				this.handler,
				true
			);
			this.handler = null;
		}
	}

	private updateBgClass(): void {
		const isScrolledDown = window.pageYOffset > this.MainHeaderPosition.height;
		const applyBg = isScrolledDown || !this.isEnabled;
		if (this.isBackgroundApplied !== applyBg) {
			this.isBackgroundApplied = applyBg;
			this.classes = applyBg ? this.bgClass : '';
			this.ref.detectChanges();
		}
	}

}
