import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

@Injectable({
	providedIn: 'root'
})
export class ExpiryRuleSvc {
	constructor(
		private http: HttpClient
	) {
	}

	public getExpiryRules(accountId: string): Promise<any> {
		return lastValueFrom(this.http.get<any>(`/media/accounts/${accountId}/expiry-rules`));
	}
}
