import { Transition, StateDeclaration } from '@uirouter/angular';

import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';
import { VbCenteredSpinnerComponent } from 'rev-shared/ui/spinner/VbCenteredSpinner.Component';

/**
 * Helper function to define a state with a spinner for the component, and a redirect to a different state
 */
export function buildLoaderStateDeclaration(targetState: string, cfg: Partial<IVbNg2StateDeclaration>): IVbNg2StateDeclaration {
	return {
		...cfg,
		component: VbCenteredSpinnerComponent,
		onEnter(transition: Transition, state: StateDeclaration) {
			const params = { ...transition.params() };
			delete params['#'];

			transition.onSuccess({}, () => {
				setTimeout(() => transition.router.stateService.go(targetState, transition.params()));
			});
		}
	} as IVbNg2StateDeclaration;
}
