import { Component, Input, Directive } from '@angular/core';

import { BrandingHeaderType } from 'rev-portal/branding/BrandingHeaderType';
import { HeaderSettings } from 'rev-portal/branding/BrandingSettings.Contract';
import { defaultHelpUri } from 'rev-portal/branding/Defaults';
import { ThemeService } from 'rev-portal/branding/Theme.Service';
import { MobileSubMenu } from 'rev-portal/navigation/mobileMenu/MobileSubMenu.Constant';

import './help.less';

@Directive({})
class BaseHelpMenuComponent {

	constructor(
		public ThemeService: ThemeService
	) { }

	public get helpLinks(): HeaderSettings {
		return this.ThemeService.brandingSettings.headerSettings;
	}

	public get helpUri(): string {
		return this.helpLinks.helpUri || defaultHelpUri;
	}

	public get hasCustomHelpLinks(): boolean {
		return this.helpLinks.customHelpLinks.length > 0;
	}

}

@Component({
	selector: 'help-menu',
	templateUrl: './HelpMenu.Component.html'
})
export class HelpMenuComponent extends BaseHelpMenuComponent {
	@Input() public buttonMode: boolean;

	public get isTwoTierTheme() {
		return this.ThemeService.brandingSettings.headerSettings.brandingHeaderType === BrandingHeaderType.TwoTier;
	}
}

@Component({
	selector: 'help-menu-mobile',
	templateUrl: './HelpMenuMobile.Component.html'
})
export class HelpMenuMobileComponent extends BaseHelpMenuComponent {
	@Input() private ngModel: string;
	@Input() private currentTheme: string;

	public get showHelpSubMenu(): boolean {
		return this.ngModel === MobileSubMenu.Help;
	}

	public syncModelValue(): void {
		this.ngModel = this.ngModel === MobileSubMenu.Help ? undefined : MobileSubMenu.Help;
	}

	public get isNettrixTheme(): boolean {
		return this.currentTheme === BrandingHeaderType.Nettrix;
	}
}
