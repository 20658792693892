export enum WebcastRunType {
	MainEvent = 'MainEvent',
	PreProduction = 'PreProduction'
}

export enum WebcastRunStatus {
	Scheduled = 'Scheduled',
	Starting = 'Starting',
	InProgress = 'InProgress',
	Ended = 'Ended'
}

export class RunItem {
	public runNumber: number;
	public runType: WebcastRunType;
	public startDate?: Date;
	public endDate?: Date;
	public status: WebcastRunStatus;
	public recordingUploaderUserId: string;

	constructor(data: any) {
		Object.assign(this, data);
	}

	public get isPreProduction(): boolean {
		return this.runType === WebcastRunType.PreProduction;
	}

	public get isMainEvent(): boolean {
		return this.runType === WebcastRunType.MainEvent;
	}

	public get isScheduled(): boolean {
		return this.status === WebcastRunStatus.Scheduled;
	}

	public get isInProgress(): boolean {
		return this.status === WebcastRunStatus.InProgress;
	}

	public get isStarted(): boolean {
		return this.status === WebcastRunStatus.InProgress ||
			this.status === WebcastRunStatus.Starting;
	}

	public get isEnded(): boolean {
		return this.status === WebcastRunStatus.Ended;
	}
}
