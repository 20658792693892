import { Component, Input } from '@angular/core';

import './video-text-status-component.less';

import { ApprovalStatus } from 'rev-shared/media/MediaConstants';

@Component({
	selector: 'video-text-status',
	templateUrl: './VideoTextStatus.Component.html',
})
export class VideoTextStatusComponent {
	@Input() public approvalStatus: ApprovalStatus;
	@Input() public hasMediaEditAuth: boolean;
	@Input() public isOnLegalHold: boolean;
	@Input() public isPasswordProtected: boolean;
	@Input() public isUnlisted: boolean;
	@Input() public isVideoOwner: boolean;
	@Input() public videoIsActive: boolean;

	public get inactive(): boolean {
		return this.isOnLegalHold ||
			!this.videoIsActive
			&& (!this.approvalStatus || this.approvalStatus === ApprovalStatus.APPROVED);
	}

	public get rejected(): boolean {
		return this.approvalStatus === ApprovalStatus.REJECTED;
	}

	public get pendingApproval(): boolean {
		return this.approvalStatus === ApprovalStatus.PENDING_APPROVAL;
	}

	public get requiresApproval(): boolean {
		return this.approvalStatus === ApprovalStatus.REQUIRES_APPROVAL;
	}
}
