import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { TemplateDialogComponent } from './TemplateDialogAngular.Component';


import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { DialogAngularModule } from 'rev-shared/ui/dialog/DialogAngular.Module';
import { DialogRegistrationService } from 'rev-shared/ui/dialog/DialogRegistration.Service';
import { FormGroupAngularModule } from 'rev-shared/ui/formGroup/FormGroupAngular.Module';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { TextInputAngularModule } from 'rev-shared/ui/text-input/TextInputAngular.Module';
import { ValidationAngularModule } from 'rev-shared/ui/validation/ValidationAngular.Module';
import { ValidationModule } from 'rev-shared/util/directives/form/validation/Validation.AngularModule';

@NgModule({
	declarations: [
		TemplateDialogComponent
	],
	exports:[
		TemplateDialogComponent
	],

	imports: [
		ButtonsAngularModule,
		CommonModule,
		DialogAngularModule,
		FormGroupAngularModule,
		FormsModule,
		SpinnerAngularModule,
		TextInputAngularModule,
		TranslateModule,
		ValidationAngularModule,
		ValidationModule
	]
})
export class TemplatesAngularModule {
}

@NgModule({
})
export class TemplatesRootModule {
	constructor(dialog: DialogRegistrationService) {
		dialog.register('templateDialog', {
			component: TemplateDialogComponent
		});
	}
}
