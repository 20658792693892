import { NgModule } from '@angular/core';

import { VbCaretDirective } from './VbCaret.Directive';

const directives = [
	VbCaretDirective
];

@NgModule({
	declarations: directives,
	exports: directives
})
export class CaretAngularModule {}
