import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { DialogService } from 'rev-shared/ui/dialog/Dialog.Service';
import { VbConfirmationDialogComponent } from 'rev-shared/ui/dialog/VbConfirmationDialogAngular.Component';
import { SparkService } from 'rev-portal/media/spark/Spark.Service';

import { MediaStateService } from 'rev-portal/media/MediaState.Service';

import { ISubscriptionContext, IUserSubscriptionType } from './IUserSubscriptionType';
import { UserSubscriptionMethod } from './UserSubscriptionMethod';
import { UserSubscriptionsService } from './UserSubscriptions.Service';
import { noop } from 'rev-shared/util';
import { SubscribeSparkCategoryComponent } from 'rev-portal/media/spark/SubscribeSparkCategory.Component';

@Component({
	selector: 'vb-user-subscriptions',
	templateUrl: './VbUserSubscriptions.Component.html',
	host: {
		display: 'flex'
	}
})
export class VbUserSubscriptionsComponent implements OnChanges {
	@Input() public category: any;
	@Input() public team: any;

	public userSubscriptionTypes: IUserSubscriptionType[];

	@ViewChild('sparkUnsubscribe')
	public unsubscribeConfirmation: VbConfirmationDialogComponent;

	public context: ISubscriptionContext;
	public sparkSubscription: any;

	constructor(
		private DialogService: DialogService,
		private UserContext: UserContextService,
		public MediaStateService: MediaStateService,
		public MediaFeaturesService: MediaFeaturesService,
		private TranslateService: TranslateService,
		private UserSubscriptionsService: UserSubscriptionsService,
		private SparkService: SparkService

	){}

	public get allowSubscription(): boolean {
		return this.UserContext.isUserAuthenticated();
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (this.isInvalidContext()) {
			this.userSubscriptionTypes = [];
			return;
		}

		if (changes.category || changes.team) {
			this.shapeSubscriptionContext();
			this.assignUserSubscriptionTypes();
		}
	}

	public onSubscriptionChange(subscription: IUserSubscriptionType) {
		subscription.method === UserSubscriptionMethod.Content
			? this.manageContentSubscription(subscription) : this.manageSparkSubscription(subscription);
	}

	public manageSparkSubscription(subscription: IUserSubscriptionType): void {
		subscription.isSubscribed ?
			this.subscribeSpark() : this.unsubscribeSpark();
	}

	public manageContentSubscription(subscription: IUserSubscriptionType): void {
		this.UserSubscriptionsService.saveContentSubscription(this.context.id, this.context.type, subscription.isSubscribed)
			.catch(err => {
				if (err) {
					console.error('error in content subscription manage', err);
				}
				this.assignUserSubscriptionTypes();
			});
	}

	public subscribeSpark(): Promise<any> {
		return this.DialogService.getDialog<SubscribeSparkCategoryComponent>('SubscribeToCategoryInSpark').open({
			initialState: {
				category: this.category
			},
		}).content.result
			.then(() => this.assignSparkSubscription())
			.catch(err => {
				if (err) {
					console.error('error in sparck subscription', err);
				}

				this.assignUserSubscriptionTypes();
			});
	}

	public unsubscribeSpark(): void {
		this.unsubscribeConfirmation.open()
			.result
			.then(
				() => this.SparkService.cancelCategorySubscription(this.category.id),
				err => {
					if (err) {
						console.error('error in sparck unsubscription', err);
					}
					this.assignUserSubscriptionTypes();
				}
			);
	}

	private assignUserSubscriptionTypes(): void {
		this.userSubscriptionTypes = [...this.getSubscriptionTypes()];
	}

	private getSubscriptionTypes(): IUserSubscriptionType[] {
		const sparkSub =
			this.context.type === 'Category' &&
			this.MediaStateService.showSubscribeSparkBtn &&
			this.MediaFeaturesService.accountFeatures.enableSpark && {
				method: UserSubscriptionMethod.WebexTeams,
				subscribeLabel: this.TranslateService.instant('Media_Videos_Spark_Subscribe'),
				unsubscribeLabel: this.TranslateService.instant('Unsubscribe'),
				isSubscribed: this.SparkService.categorySubscriptions[this.context.id]
			};

		return [
			this.MediaFeaturesService.accountFeatures.enableContentNotifications && {
				method: UserSubscriptionMethod.Content,
				subscribeLabel: sparkSub ? this.TranslateService.instant('UserSubscription_SystemNotification') : this.TranslateService.instant('Subscribe'),
				unsubscribeLabel: this.TranslateService.instant('Unsubscribe'),
				isSubscribed: this.UserSubscriptionsService.contentSubscriptions[this.context.type.toLowerCase()][this.context.id]
			},
			sparkSub
		].filter(Boolean);
	}

	private shapeSubscriptionContext(): void {
		this.context = {
			id: this.team?.id || this.category?.id,
			type: this.team?.id ? 'Channel' :
				this.category?.id ? 'Category' : undefined
		};

		this.assignSparkSubscription();
	}

	private assignSparkSubscription(): void {
		if (this.category) {
			this.sparkSubscription = this.SparkService.categorySubscriptions?.[this.category.id];
		}
	}

	private isInvalidContext(): boolean {
		return !this.team && !this.category?.id;
	}
}
