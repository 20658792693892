import {
	Component,
	Input,
	OnChanges,
	SimpleChanges
} from '@angular/core';

import { BrandingSettings } from 'rev-portal/branding/BrandingSettings.Contract';

import './featured-video-thumbnail-grid.less';

@Component({
	selector: 'featured-video-thumbnail-grid',
	template: `
		<div
			class="outer-layout"
			layout="row"
			layout-wrap="false">

			<featured-video-thumbnail
				flex="50"
				align-title-bottom
				[video]="leftVideo">
			</featured-video-thumbnail>

			<div
				class="quarter-grid"
				flex="50"
				layout="row"
				*ngIf="rightVideos.length">
				<featured-video-thumbnail
					flex="50"
					*ngFor="let video of rightVideos"
					align-title-bottom
					[video]="video">
				</featured-video-thumbnail>
			</div>
		</div>
	`
})
export class FeaturedVideoThumbnailGridComponent implements OnChanges {
	@Input() public videos: any[];

	public leftVideo: any;
	public rightVideos: any[];

	public ngOnChanges(changes: SimpleChanges): void {
		if(changes.videos) {
			this.leftVideo = this.videos[0];
			this.rightVideos = this.videos.slice(1, 5);
		}
	}
}
