import { NgModule, APP_BOOTSTRAP_LISTENER } from '@angular/core';

import { UIRouter, UIRouterModule } from '@uirouter/angular';

import { BootstrapContext } from 'rev-shared/bootstrap/BootstrapContext';

import { initGoogleAnalytics, trackPage } from './GoogleAnalyticsUtils';

const isGoogleAnalyticsTrackingEnabled: boolean = !isGoogleAnalyticsTrackingDisabled();

@NgModule({
	imports: [
		UIRouterModule.forChild({
			config: trackPages
		})
	],
	providers: [
		{
			provide: APP_BOOTSTRAP_LISTENER,
			deps: [
				UIRouter
			],
			useFactory: bootstrapGoogleAnalytics,
			multi: true
		}
	]
})
export class GoogleAnalyticsModule {}

function bootstrapGoogleAnalytics(uiRouter: UIRouter) {
	return () => {
		if (isGoogleAnalyticsTrackingEnabled) {
			initGoogleAnalytics();
			trackCurrentPage(uiRouter);
		}
	};
}

function trackCurrentPage(uiRouter: UIRouter): void {
	trackPage(uiRouter.locationService.path());
}

function trackPages(uiRouter: UIRouter): void {
	if (isGoogleAnalyticsTrackingEnabled) {
		uiRouter.transitionService.onSuccess({}, () => trackCurrentPage(uiRouter));
	}
}

function isGoogleAnalyticsTrackingDisabled(): boolean {
	return !BootstrapContext.environmentGoogleAnalyticsEnabled
		|| !BootstrapContext.googleAnalyticsTrackingCode
		|| BootstrapContext.account.googleAnalyticsDisabled;
}
