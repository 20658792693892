import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UIRouterModule } from '@uirouter/angular';

import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { CaretAngularModule } from 'rev-shared/ui/caret/CaretAngular.Module';
import { CssRulesAngularModule } from 'rev-shared/ui/css/Css.AngularModule';
import { SecurityAngularModule } from 'rev-shared/security/SecurityAngular.Module';
import { SharedTeamAngularModule } from 'rev-shared/team/SharedTeam.AngularModule';
import { SpinnerRootModule } from 'rev-shared/ui/spinner/SpinnerRoot.Module';
import { VbrickSharedMediaModule } from 'rev-shared/media/Media.Module';

import { MediaMenuComponent } from './MediaMenu.Component';
import { MediaMenuDropdownComponent } from './MediaMenuDropdown.Component';
import { MyTeamsMenuItemsComponent } from './MyTeamsMenuItems.Component';
import { MyVideosMenuItemsComponent } from './MyVideosMenuItems.Component';

const declarations = [
	MediaMenuComponent,
	MediaMenuDropdownComponent,
	MyTeamsMenuItemsComponent,
	MyVideosMenuItemsComponent
];

@NgModule({
	declarations,
	imports: [
		BsDropdownModule,
		CaretAngularModule,
		CommonModule,
		CssRulesAngularModule,
		SecurityAngularModule,
		SharedTeamAngularModule,
		SpinnerRootModule,
		TranslateModule,
		UIRouterModule,
		VbrickSharedMediaModule
	],
	exports: declarations
})

export class MediaMenuAngularModule { }
