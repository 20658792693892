import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';

import { SharedTeamAngularModule } from 'rev-shared/team/SharedTeam.AngularModule';
import { TeamsAngularModule } from 'rev-portal/media/teams/Teams.AngularModule';
import { UtilAngularModule } from 'rev-shared/util/UtilAngular.Module';
import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';

import { CategoryWidgetComponent } from './CategoryWidget.Component';
import { EventWidgetComponent } from './EventWidget.Component';
import { TeamWidgetComponent } from './TeamWidget.Component';
import { WidgetWrapperComponent } from './WidgetWrapper.Component';

@NgModule({
	id: 'VBrick.Portal.Widget',
	declarations: [
		CategoryWidgetComponent,
		EventWidgetComponent,
		TeamWidgetComponent,
		WidgetWrapperComponent
	],
	exports: [
		CategoryWidgetComponent,
		EventWidgetComponent,
		TeamWidgetComponent
	],
	imports: [
		CommonModule,
		DateAngularModule,
		SharedTeamAngularModule,
		SpinnerAngularModule,
		TeamsAngularModule,
		TranslateModule,
		UIRouterModule.forChild(),
		UtilAngularModule
	]
})
export class WidgetModule {}
