import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';

import { buildLoaderStateDeclaration } from 'rev-shared/uiRouterUtils/LazyLoaderStateUtil';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { PORTAL_ADMIN_LOADER_STATE, PORTAL_ADMIN_STATE } from 'rev-shared/bootstrap/AppStateNames';

@NgModule({
	imports: [
		SpinnerAngularModule,
		UIRouterModule.forChild({
			states: [{
				name: 'portal.admin.**',
				url: '/admin/:accountId',
				authorizationKey: 'admin',
				loadChildren: () => {
					return import(/* webpackChunkName: 'admin' */ 'rev-portal/admin/AdminAngular.Module')
						.then(m => m.AdminAngularModule);
				}
			},
			buildLoaderStateDeclaration(PORTAL_ADMIN_STATE, {
				name: PORTAL_ADMIN_LOADER_STATE,
				url: '/load-admin/:accountId',
				authorizationKey: 'admin',
			})]
		})
	]
})
export class AdminLoaderModule {
}
