import { NgModule, APP_BOOTSTRAP_LISTENER } from '@angular/core';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { DialogService } from 'rev-shared/ui/dialog/Dialog.Service';
import { DialogRegistrationService } from 'rev-shared/ui/dialog/DialogRegistration.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

import { SystemMessageService } from './SystemMessage.Service';

@NgModule({
	imports: [
		TranslateModule
	],
	providers: [
		DialogRegistrationService,
		{
			provide: APP_BOOTSTRAP_LISTENER,
			deps: [UserContextService, SystemMessageService, DialogService, TranslateService],
			multi: true,
			useFactory: (UserContext: UserContextService, SystemMessageService: SystemMessageService, Dialog: DialogService, translate: TranslateService) => () => {
				UserContext.userIdChanged$.subscribe(showMessage);
				showMessage();

				function showMessage() {

					if (!UserContext.isUserAuthenticated()) {
						return;
					}

					SystemMessageService.loadUserMessage()
						.then(message => {
							if (message) {
								const params = {
									message,
									title: translate.instant('SystemMessage'),
									actionText: translate.instant('Ok'),
									messageBodyClass: 'util-white-space-pre-wrap'
								};
								Dialog.openConfirmationDialog(params).result.then(() => {
									SystemMessageService.markMessageAsRead();
								});
							}
						});
				}
			}
		}
	]
})
export class SystemMessageModule {
}
