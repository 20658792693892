import {
	Component,
	Input,
	OnInit,
} from '@angular/core';

import { ScheduledEventService } from 'rev-shared/scheduledEvents/ScheduledEvent.Service';

import styles from './list-widget.module.less';
import moment from 'moment';
import { OnDestroy } from '@angular/core';

@Component({
	selector: 'event-widget',
	templateUrl: './EventWidget.Component.html'
})
export class EventWidgetComponent implements OnInit, OnDestroy {
	@Input() public accountId: string;

	public readonly styles = styles;
	public readonly now = Date.now();

	public events: any;
	public isFetching: boolean = true;
	public timeFrom: string[] = [];
	public timeFromTimer;

	constructor(
		private ScheduledEventService: ScheduledEventService
	) {}

	public ngOnInit(): void {
		this.loadUpcomingEvents();
		this.timeFromTimer = setInterval(() => {
			this.timeFrom = this.events.map(e => moment(e.startDate.getTime()).fromNow());
		}, 1000);
	}

	public ngOnDestroy(): void {
		clearInterval(this.timeFromTimer);
	}

	private loadUpcomingEvents(): void {
		this.isFetching = true;
		this.ScheduledEventService.getScheduledEvents(this.accountId, new Date(), new Date())
			.then(events => this.events = events)
			.finally(() => this.isFetching = false);
	}
}
