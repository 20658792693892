import {
	Component,
	Input
} from '@angular/core';

import styles from './widget-wrapper.module.less';

@Component({
	selector: 'widget-wrapper',
	template: `
		<section [ngClass]="styles.layout">
			<h4 [ngClass]="styles.header">
				<span [ngClass]="styles.headerText">
					<a [ngClass]="styles.headerLink"
						*ngIf="headerLink; else noLink"
						[uiSref]="headerLink">
						{{headerText}}
					</a>
					<ng-template #noLink>{{headerText}}</ng-template>
				</span>
				<div flex="1"></div>
			</h4>
			<ng-content></ng-content>
		</section>`
})
export class WidgetWrapperComponent {
	@Input() public headerText: string;
	@Input() public headerLink: string;

	public readonly styles = styles;
}
