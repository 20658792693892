import { NgModule } from '@angular/core';

import { UserNavMenuModule } from 'rev-portal/navigation/userNavMenu/UserNavMenu.Module';
import { UploadMenuModule } from 'rev-portal/navigation/uploadMenu/UploadMenu.Module';

import { HelpModule } from './help/Help.Module';
import { MainHeaderAngularModule } from './mainHeader/MainHeader.AngularModule';
import { MediaMenuAngularModule } from './mediaMenu/MediaMenuAngular.Module';
import { NotificationsMenuModule } from './notificationsMenu/NotificationsMenuAngular.Module';
@NgModule({
	imports: [
		UserNavMenuModule,
		UploadMenuModule,
		NotificationsMenuModule,
		MediaMenuAngularModule,
		MainHeaderAngularModule,
		HelpModule
	]
})

export class NavigationAngularModule { }
