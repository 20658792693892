import { InjectionToken } from '@angular/core';

import { MomentLocaleLoader } from 'rev-shared/date/MomentLocaleLoader.Provider';

import { ThemeService } from 'rev-portal/branding/Theme.Service';

export interface IEntryPointStartup {
	$promise: Promise<any>;
}

export const EntryPointStartup: InjectionToken<IEntryPointStartup> = new InjectionToken('EntryPointStartup');

export function getEntryPointStartup(
	MomentLocaleLoader: MomentLocaleLoader,
	SecurityStartup: Promise<void>,
	ThemeService: ThemeService,
	SignalRStartup: Promise<void>,
): IEntryPointStartup {
	return {
		$promise: Promise.all([
			MomentLocaleLoader.load(),
			SecurityStartup,
			ThemeService.initialize(),
			SignalRStartup
		])
			.catch(e => {
				console.error('portal Startup error: ', e);
				return Promise.reject(e);
			})
	};
}
