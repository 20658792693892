import {
	Component,
	Input,
	OnInit
} from '@angular/core';

import { SidebarWidgetType } from 'rev-portal/branding/BrandingSettings.Contract';

import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';

import styles from './portal-dashboard-sidebar.module.less';

@Component({
	selector: 'portal-dashboard-sidebar',
	template: `
		<section
			[ngClass]="styles.widgetWrapper"
			*ngFor="let widget of sidebarWidgetSettings"
			[ngSwitch]="widget">
			<ng-container *ngSwitchCase="'Categories'">
				<category-widget
					*ngIf="enableCategories"
					[accountId]="accountId">
				</category-widget>
			</ng-container>
			<team-widget *ngSwitchCase="'Teams'">
			</team-widget>
			<event-widget *ngSwitchCase="'Event'"
				[accountId]="accountId">
			</event-widget>
		</section>
	`
})
export class PortalDashboardSidebarComponent implements OnInit {
	@Input() public accountId: string;
	@Input() public sidebarWidgetSettings: SidebarWidgetType[];

	public readonly styles = styles;

	public enableCategories: boolean;

	constructor(
		private MediaFeatures: MediaFeaturesService
	) { }

	public ngOnInit(): void {
		this.MediaFeatures.getFeatures(this.accountId)
			.then(result => this.enableCategories = result.enableCategories);
	}

}
