import {
	Component,
	OnInit
} from '@angular/core';

import { TeamListingsService } from 'rev-portal/media/teams/TeamListings.Service';

import styles from 'rev-portal/dashboard/carousels/dashboard-carousel.module.less';

@Component({
	selector: 'teams-carousel',
	templateUrl: './TeamsCarousel.Component.html'
})

export class TeamsCarouselComponent implements OnInit {
	public readonly styles = styles;

	private accountId: string;
	public teams: any[];

	constructor(
		private TeamListingsService: TeamListingsService
	) {}

	public ngOnInit(): void {
		this.loadUserTeams();
	}

	public loadUserTeams() {
		return this.TeamListingsService.listTeams()
			.then(teams => this.teams = teams);
	}
}
