import {
	Component
} from '@angular/core';

import { INotification } from './Notification.Contract';
import { UserNotificationsService, INotificationGroup } from './UserNotifications.Service';

@Component({
	selector: 'user-notifications',

	templateUrl: './UserNotifications.Component.html'
})
export class UserNotificationsComponent {
	public status: {[status: string]: boolean } = {};

	public get highNotifications(): INotification[] {
		return this.UserNotifications.highNotifications;
	}

	public get lowNotifications(): INotificationGroup[] {
		return this.UserNotifications.lowNotifications;
	}

	constructor(
		private UserNotifications: UserNotificationsService
	) {
		this.refresh();
	}

	private refresh(): void {
		this.status = { loading: true };

		this.UserNotifications.$promise
			.then(() => this.status = { active : true })
			.catch(() => this.status = { error : true });
	}

}
