import {
	Component,
	EventEmitter,
	Input,
	Output
} from '@angular/core';

import '../vbMediaTable.less';

@Component({
	selector: 'vb-media-table',
	template: `<ng-content></ng-content>`
})
export class VbMediaTableAngularComponent {
	@Input() public columns: any[];
	@Input() public sortField: string;
	@Input() public sortAscending: boolean;
	@Input() public isSortDisabled: boolean;

	@Output() public onColumnHeaderClick: EventEmitter<any> = new EventEmitter();
}
