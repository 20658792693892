<section class="video-status">
	<div class="video-status-text" *ngIf="requiresApproval">
		<span>{{ 'RequiresApproval' | translate }}</span>
	</div>

	<div class="video-status-text pending" *ngIf="pendingApproval">
		<span>{{ (isVideoOwner ? 'PendingApproval' : 'ApprovalRequested') | translate }}</span>
	</div>

	<div class="video-status-text rejected" *ngIf="rejected">
		<span>{{ 'Rejected' | translate }}</span>
	</div>

	<div class="video-status-text inactive" *ngIf="inactive">
		<span>{{ 'Inactive' | translate }}</span>
	</div>

	<div class="video-status-legal" *ngIf="isOnLegalHold">
		<span class="glyphicons lock"></span>
		<span class="util-uppercase">{{ 'Video_LegalHold' | translate }}</span>
	</div>
	<unlisted-video-indicator [isVideoUnlisted]="isUnlisted" [hasMediaEditAuth]="hasMediaEditAuth">
	</unlisted-video-indicator>

	<div class="video-status-public-password" *ngIf="isPasswordProtected" [tooltip]="'PasswordProtected' | translate " container="body" placement="right">
		<span class="vb-icon vb-icon-password"></span>
	</div>
</section>
