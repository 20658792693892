import { NgModule } from '@angular/core';
import { UIRouterModule, Transition } from '@uirouter/angular';

import { once } from 'underscore';

import { VIDEO_PLAYBACK_STATE_NAME, VIDEO_PLAYLIST_PLAYBACK_STATE_NAME, VIDEO_SETTINGS_STATE_NAME } from './videoPlayback/Constants';


const loadChildren = once(() =>
	import(/* webpackChunkName: 'video-playback' */ './VideosAngular.Module')
		.then(mod => mod.VideosAngularModule)
);

@NgModule({
	imports: [
		UIRouterModule.forChild({
			states: [{
				name: `${VIDEO_PLAYBACK_STATE_NAME}.**`,
				url: '/videos/:videoId?startAt&autoplay&query',
				loadChildren
			}, {
				name: `${VIDEO_PLAYLIST_PLAYBACK_STATE_NAME}.**`,
				url: '/playlist/:playlistId/videos/?videoId',
				loadChildren
			}, {
				name: `${VIDEO_SETTINGS_STATE_NAME}.**`,
				url: '/video-settings/:videoId',
				loadChildren
			}]
		})
	]
})
export class VideosLoaderModule {
}
