import { Component, Input, HostBinding, ViewChild } from '@angular/core';
import { MediaStateService } from '../../MediaState.Service';
import { StateService } from '@uirouter/angular';

import { TeamService } from 'rev-shared/team/Team.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { VbConfirmationDialogComponent } from 'rev-shared/ui/dialog/VbConfirmationDialogAngular.Component';
import { noop } from 'rev-shared/util';

import styles from './mediaSearchToolbar.module.less';

@Component({
	selector: 'vb-media-teams-header',
	templateUrl: './VbMediaTeamsHeader.Component.html'
})
export class VbMediaTeamsHeaderComponent {
	@Input() public team: any;

	@HostBinding('style.background-color')
	public get bgColor() {
		return this.team.bgColor;
	}

	@ViewChild(VbConfirmationDialogComponent)
	public deleteConfirmation: VbConfirmationDialogComponent;

	public readonly styles = styles;
	public readonly accountId = this.UserContext.getAccount().id;

	constructor(
		public readonly MediaStateService: MediaStateService,
		public readonly TeamService: TeamService,
		public readonly UserContext: UserContextService,
		public readonly $state: StateService
	)
	{}

	public onDeleteTeam(): void {
		this.deleteConfirmation.open().result.then(
			() => this.TeamService.removeTeam(this.accountId, this.team.id)
				.then(() => this.$state.go('portal.team'))
				.catch(err => console.log('DeleteTeam Error: ', err)),
			noop
		);
	}
}
