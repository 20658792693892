import { Directive } from '@angular/core';

import './vb-caret.less';

@Directive({
	selector: '[vbCaretDirective]',
	host: {
		class: 'glyphicons play'
	}
})

export class VbCaretDirective {
}

