import {
	Component,
	Input
} from '@angular/core';

import { INotification } from './Notification.Contract';
import './notification.less';

@Component({
	selector: 'vb-notification',

	templateUrl: './Notification.Component.html'
})
export class NotificationComponent {
	@Input() public notification: INotification;
}
