<widget-wrapper [headerText]="'Navigation_LiveEvents' | translate" [headerLink]="'portal.scheduledEvents'">

	<vb-loading-spinner [block]="true" [size]="'large'" *ngIf="isFetching; else ready">
	</vb-loading-spinner>

	<ng-template #ready>
		<section [ngClass]="{'animated-extended fadeIn': !isFetching}" *ngIf="events.length; else noEvents">
			<ul [ngClass]="styles.listWrap">
				<li *ngFor="let event of events; let i = index" [ngClass]="styles.listItem">
					<a [ngClass]="styles.listItemText" uiSref="portal.scheduledEvents.webcast" [uiParams]="{webcastId: event.id}">
						<span>
							{{event.title}}
						</span>
					</a>
					<div [ngClass]="styles.eventDate">
						{{'Widget_Event_Time' | translate : { '0': timeFrom[i] } }}
					</div>
				</li>
			</ul>
		</section>

		<ng-template #noEvents>
			<section [ngClass]="styles.noResultsContainer" class="text-align-center padding-top-15">
				<span class="control-label">
					{{ 'Dashboard_No_Events' | translate }}
				</span>
			</section>
		</ng-template>
	</ng-template>
</widget-wrapper>
