import { TeamListingsService } from 'rev-portal/media/teams/TeamListings.Service';

import { CategoryService } from 'rev-shared/media/Category.Service';
import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { SparkService } from 'rev-portal/media/spark/Spark.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { UserSubscriptionsService } from 'rev-shared/userSubscription/UserSubscriptions.Service';

import { ManageSubscriptionsComponent } from './ManageSubscriptions.Component';

export const states: IVbNg2StateDeclaration[] = [{
	name: 'portal.manageSubscriptions',
	url: '/manage-subscriptions',
	component: ManageSubscriptionsComponent,
	resolve: [{
		token: 'init',
		deps: [MediaFeaturesService, SparkService, UserContextService, UserSubscriptionsService],
		resolveFn: (
			MediaFeaturesService: MediaFeaturesService,
			SparkService: SparkService,
			UserContextService: UserContextService,
			UserSubscriptionsService: UserSubscriptionsService
		) => {
			return Promise.all([
				UserSubscriptionsService.loadUserSubscriptions(),
				MediaFeaturesService.getFeatures(UserContextService.getAccount().id),
				SparkService.loadCategorySubscriptions()
			]);
		}
	}, {
		token: 'flattenedCategories',
		deps: ['init', CategoryService, MediaFeaturesService, UserContextService],
		resolveFn: (
			init,
			CategoryService: CategoryService,
			MediaFeaturesService: MediaFeaturesService,
			UserContextService: UserContextService
		) => MediaFeaturesService.accountFeatures.enableContentNotifications || MediaFeaturesService.accountFeatures.enableSpark ? CategoryService.getFlattenedCategories(UserContextService.getAccount().id) : []
	}, {
		token: 'allChannels',
		deps: ['init', MediaFeaturesService, TeamListingsService],
		resolveFn: (
			init,
			MediaFeaturesService: MediaFeaturesService,
			TeamListingsService: TeamListingsService
		) => MediaFeaturesService.accountFeatures.enableContentNotifications || MediaFeaturesService.accountFeatures.enableSpark ? TeamListingsService.listTeams() : []
	}, {
		token: 'categories',
		deps: ['flattenedCategories', SparkService, UserSubscriptionsService],
		resolveFn: (
			flattenedCategories,
			SparkService: SparkService,
			UserSubscriptionsService: UserSubscriptionsService
		) => {
			const categorySubscriptions = Object.keys(SparkService.categorySubscriptions).concat(Object.keys(UserSubscriptionsService.contentSubscriptions.category));
			return flattenedCategories
				.filter(cat => categorySubscriptions.some(id => id === cat.categoryId))
				.map(cat => {
					return {
						id: cat.categoryId,
						type: 'Category',
						label: cat.name,
						path: cat.fullPath,
						emailNotifications: Object.keys(UserSubscriptionsService.contentSubscriptions.category).includes(cat.categoryId),
						webexTeamsNotifications: Object.keys(SparkService.categorySubscriptions).includes(cat.categoryId)
					};
				});
		}
	}, {
		token: 'channels',
		deps: ['allChannels', UserSubscriptionsService],
		resolveFn: (
			allChannels,
			UserSubscriptionsService: UserSubscriptionsService
		) => {
			return allChannels
				.filter(channel => Object.keys(UserSubscriptionsService.contentSubscriptions.channel).some(id => id === channel.id))
				.map(channel => {
					return {
						id: channel.id,
						type: 'Channel',
						label: channel.name,
						emailNotifications: true
					};
				});
		}
	}]
}];
