import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { mapObject } from 'underscore';

@Injectable({
	providedIn: 'root'
})
export class VbTranslateService {

	constructor(
		private Translate: TranslateService
	) {}

	public translate(key: string, params?: any){
		return this.Translate.instant(key, params);
	}

	public translateKeys(keys: [string]): [string];
	public translateKeys(keys: [string, string]): [string, string];
	public translateKeys(keys: [string, string, string]): [string, string, string];

	public translateKeys(keys: string[]): string[] {
		return keys.map(k => this.translate(k));
	}

	public translateObj<T>(obj: T): T {
		return mapObject(obj as any, k => this.translate(k));
	}

	public translateObjWParams(obj: {[k:string]: [string, any?]}): any {
		return mapObject(obj as any, ([key, params]: [string, any]) => this.translate(key, params));
	}

}
