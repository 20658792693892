import {
	Component,
	Input,
	OnDestroy,
	AfterViewInit
} from '@angular/core';

import {
	CarouselConfig,
	CarouselType,
	HomepageTemplateType
} from 'rev-portal/branding/BrandingSettings.Contract';
import { IAccountLicense } from 'rev-shared/security/IAccountLicense';
import { MainHeaderPositionService } from 'rev-portal/navigation/mainHeader/MainHeaderPosition.Service';

import { ThemeService } from 'rev-portal/branding/Theme.Service';

import './portal-dashboard.less';

import styles from './portal-dashboard.module.less';

@Component({
	selector: 'portal-dashboard',
	template: `
		<featured-videos
			*ngIf="featuredVideos.length"
			[accountLicense]="accountLicense"
			[videos]="featuredVideos">
		</featured-videos>

		<section [ngClass]="[
				styles.portalDashboardContainer,
				displaySidebar ? styles.portalDashboardTopSeparator : ''
			]">
			<portal-dashboard-content
				id="portal-dashboard-content"
				class="dashboard theme-primary-bg"
				[ngClass]="styles.portalDashboardContent"
				[carousels]="carousels">
			</portal-dashboard-content>

			<portal-dashboard-sidebar
				class="theme-primary-bg"
				[ngClass]="styles.portalDashboardSidebar"
				*ngIf="displaySidebar"
				[accountId]="accountId"
				[sidebarWidgetSettings]="ThemeService.brandingSettings.homepageSettings.sidebarWidgetTypes">

			</portal-dashboard-sidebar>
		</section>
	`
})
export class PortalDashboardComponent implements AfterViewInit, OnDestroy {
	@Input() public accountId: string;
	@Input() public accountLicense: IAccountLicense;
	@Input() public featuredVideos: any[];

	private readonly carouselTypes: typeof CarouselType = CarouselType;
	public readonly styles = styles;

	constructor(
		private MainHeaderPosition: MainHeaderPositionService,
		public ThemeService: ThemeService
	) {}

	public ngAfterViewInit(){
		this.MainHeaderPosition.isTransparencySupported = this.featuredVideos.length > 0;
	}
	public ngOnDestroy(){
		this.MainHeaderPosition.isTransparencySupported = false;
	}

	public get carousels(): CarouselConfig[] {
		return this.ThemeService.brandingSettings.homepageSettings.carousels;
	}

	private get mediaViewingAllowed(): boolean {
		return this.accountLicense.mediaViewingAllowed;
	}

	public get displaySidebar(): boolean {
		return this.ThemeService.brandingSettings.homepageSettings.homepageTemplateType
			=== HomepageTemplateType.CarouselsWithSidebar;
	}
}
