import { Transition } from '@uirouter/angular';

import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';
import { TeamListingsService } from './TeamListings.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { TeamsListComponent } from './TeamsList.Component';
import { sharedTeamState } from 'rev-shared/team/StateDeclarations';
import { TeamService } from 'rev-shared/team/Team.Service';

export const teamStates: IVbNg2StateDeclaration[] = getEditStateFromMedia();

function getEditStateFromMedia(): IVbNg2StateDeclaration[] {

	return [
		{
			name: 'portal.team',
			url: '/teams',
			component: TeamsListComponent,
			resolve: [
				{
					token: 'teams',
					deps: [TeamListingsService],
					resolveFn: (TeamListingsService: TeamListingsService) => TeamListingsService.listTeams(true)
				},
				{
					token: 'isCreateTeamAccess',
					deps: [TeamService],
					resolveFn: (TeamService: TeamService) => TeamService.isCreateTeamAccess
				}
			]
		},
		{
			...sharedTeamState,
			name: 'portal.team-edit',
			url:  '/edit-team/:teamId',
			params: {
				teamId: null
			},
			resolve: [
				...sharedTeamState.resolve,
				{
					token: 'previousState',
					deps: [Transition],
					resolveFn: ($transition$: Transition) => {
						return {
							name: $transition$.from().name,
							params: $transition$.params('from')
						};
					}
				},
				{
					token: 'accountId',
					deps: [UserContextService],
					resolveFn: (UserContextService: UserContextService) => UserContextService.getAccount().id
				}
			]
		}
	];
}
