import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';

import { BrandingAngularModule } from 'rev-portal/branding/BrandingAngular.Module';
import { HelpModule } from 'rev-portal/navigation/help/Help.Module';
import { MediaMenuAngularModule } from 'rev-portal/navigation/mediaMenu/MediaMenuAngular.Module';
import { MobileMenuModule } from 'rev-portal/navigation/mobileMenu/MobileMenu.Module';
import { NotificationsMenuModule } from 'rev-portal/navigation/notificationsMenu/NotificationsMenuAngular.Module';
import { SearchHeaderAngularModule } from 'rev-portal/navigation/searchHeader/SearchHeaderAngular.Module';
import { UploadMenuModule } from 'rev-portal/navigation/uploadMenu/UploadMenu.Module';
import { UserNavMenuModule } from 'rev-portal/navigation/userNavMenu/UserNavMenu.Module';
import { GoLiveModule } from 'rev-portal/ScheduledEvents/goLive/GoLive.Module';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { CssRulesAngularModule } from 'rev-shared/ui/css/Css.AngularModule';
import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { SecurityAngularModule } from 'rev-shared/security/SecurityAngular.Module';
import { VbProfilePictureModule } from 'rev-shared/ui/profile/VbProfilePicture.Module';
import { WindowSizeAngularModule } from 'rev-shared/ui/size/WindowSize.AngularModule';

import { ClassicHeaderComponent } from './ClassicHeader.Component';
import { MainHeaderComponent } from './MainHeader.Component';
import { NettrixHeader } from './NettrixHeader.Component';
import { TransitionBackgroundDirective } from './TransitionBackground.Directive';
import { TwoTierHeader } from './TwoTierHeader.Component';


const declarations = [
	MainHeaderComponent,
	ClassicHeaderComponent,
	NettrixHeader,
	TwoTierHeader,
	TransitionBackgroundDirective
];
@NgModule({
	declarations,
	imports: [
		ButtonsAngularModule,
		BrandingAngularModule,
		BsDropdownModule,
		CommonModule,
		CssRulesAngularModule,
		GoLiveModule,
		HelpModule,
		MediaMenuAngularModule,
		MobileMenuModule,
		NotificationsMenuModule,
		SearchHeaderAngularModule,
		SecurityAngularModule,
		TranslateModule,
		UIRouterModule,
		UploadMenuModule,
		UserNavMenuModule,
		VbProfilePictureModule,
		WindowSizeAngularModule,
		DateAngularModule
	],
	exports: declarations
})
export class MainHeaderAngularModule { }
