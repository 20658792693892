import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { InlineEditAngularModule } from 'rev-shared/ui/inlineEdit/InlineEdit.AngularModule';
import { CssRulesAngularModule } from 'rev-shared/ui/css/Css.AngularModule';

import { SearchHeaderComponent } from './SearchHeader.Component';
import { SearchHeaderDropdownComponent } from './SearchHeaderDropdown.Component';

const components = [
	SearchHeaderComponent,
	SearchHeaderDropdownComponent
];
@NgModule({
	declarations: [
		components
	],
	imports: [
		BsDropdownModule,
		CommonModule,
		CssRulesAngularModule,
		InlineEditAngularModule,
		TranslateModule
	],
	exports: [
		components
	]
})

export class SearchHeaderAngularModule { }

