import {
	Component,
	HostBinding,
	Input
} from '@angular/core';

import { VbMediaTableAngularComponent } from './VbMediaTableAngular.Component';

@Component({
	selector: 'vb-media-table-column-header',
	templateUrl: './VbMediaTableColumnHeaderAngular.Component.html',
	host: {
		'[hidden]': '!(parent && parent?.columns[field] >= 0)',
		'[style.order]': 'parent && parent?.columns[field]'
	}
})
export class VbMediaTableColumnHeaderAngularComponent {
	@Input() public field: string;
	@Input() public header: string;
	@Input() public isDefaultDescending: boolean;
	@Input() public isMobileHidden: boolean;
	@Input() public mobileAlign: string;
	@HostBinding('class') public classes: string = 'table-cell file-list-header-col';

	constructor(
		public parent: VbMediaTableAngularComponent
	) {
	}

	public ngOnInit(): void {
		if (this.mobileAlign) {
			this.classes = `${this.classes} mobile-align-${this.mobileAlign}`;
		}
		if (this.isMobileHidden) {
			this.classes = `${this.classes} mobile-hidden`;
		}
	}

	public onHeaderClick(): void {
		this.parent.onColumnHeaderClick.emit({
			field: this.field,
			isDefaultDescending: this.isDefaultDescending === true
		});
	}
}
