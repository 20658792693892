import {
	Component,
	Input
} from '@angular/core';

import { CarouselConfig } from 'rev-portal/branding/BrandingSettings.Contract';

import { ThemeService } from 'rev-portal/branding/Theme.Service';

import styles from './portal-dashboard-content.module.less';

@Component({
	selector: 'portal-dashboard-content',
	template: `
		<section [ngClass]="styles.contentSection">
			<section
				[ngClass]="styles.dashboardContent"
				*ngFor="let carousel of carousels"
				[ngSwitch]="carousel.type">

					<events-carousel
						*ngSwitchCase="'LiveEvents'">
					</events-carousel>

					<teams-carousel
						*ngSwitchCase="'Teams'">
					</teams-carousel>

					<video-carousel
						*ngSwitchDefault
						[carousel]="carousel">
					</video-carousel>

			</section>
		</section>
	`
})

export class PortalDashboardContentComponent {
	@Input() public carousels: CarouselConfig[];

	public readonly styles = styles;

	constructor(
		public ThemeService: ThemeService
	) {}
}
