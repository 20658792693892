import { BootstrapContext } from 'rev-shared/bootstrap/BootstrapContext';

const GOOGLE_ANALYTICS_SCRIPT_SRC: string = 'https://www.google-analytics.com/analytics.js';

export function initGoogleAnalytics(): void {
	const ga: any = function(...args) {
		(ga.q = ga.q || []).push(args);
	};

	ga.l =+ new Date;

	(window as any).ga = ga;

	ga('create', BootstrapContext.googleAnalyticsTrackingCode, {
		cookieDomain: BootstrapContext.account.hostname,
		cookieFlags: 'SameSite=None; Secure;' //GA will be working on https only
	});

	const gaScript = document.createElement('script');
	gaScript.async = true;
	gaScript.src = GOOGLE_ANALYTICS_SCRIPT_SRC;
	document.body.appendChild(gaScript);
}

export function trackPage(path: string): void {
	(window as any).ga('send', {
		hitType: 'pageview',
		page: path,
		title: document.title
	});
}
