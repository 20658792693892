<ng-template [ngIf]="isOpen">
  <div [class]="'alert alert-' + type" role="alert" [ngClass]="classes">
    <ng-template [ngIf]="dismissible">
      <button type="button" class="close btn-close" aria-label="Close" (click)="close()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
        <span class="sr-only visually-hidden">Close</span>
      </button>
    </ng-template>
    <ng-content></ng-content>
  </div>
</ng-template>
