import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { BrandingSettings } from 'rev-portal/branding/BrandingSettings.Contract';
import { ThemeService } from 'rev-portal/branding/Theme.Service';

import { IRules } from 'rev-shared/ui/css/CssRules.Contract';
import { TeamService } from 'rev-shared/team/Team.Service';

import { MediaMenuService } from './MediaMenu.Service';

import './media-menu.less';

@Component({
	selector: 'media-menu',
	templateUrl: './MediaMenu.Component.html'
})
export class MediaMenuComponent implements OnInit, OnDestroy {

	private themeServiceSub: Subscription;
	public themeStyleOverrides: IRules;

	constructor(
		public MediaMenuService: MediaMenuService,
		private ThemeService: ThemeService,
		public TeamService: TeamService
	) { }

	public ngOnInit(): void {
		this.MediaMenuService.initiateTLSSubscription();
		this.themeServiceSub = this.ThemeService.brandingSettings$.subscribe(brandingSettings => this.applyCssRules(brandingSettings));
	}

	public ngOnDestroy(): void {
		this.MediaMenuService.unsubscribeTLSSubscription();
		this.themeServiceSub?.unsubscribe();
	}

	public applyCssRules(brandingSettings: BrandingSettings): void {
		const accentColor = brandingSettings.themeSettings.accentColor;
		this.themeStyleOverrides = {
			'media-menu .media-arrow-fg': {
				'border-color': `transparent transparent ${accentColor}`
			}
		};
	}
}
