import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';

import { VbVideoExpirationOptionsComponent } from './VbVideoExpirationOptions.Component';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { CheckboxAngularModule } from 'rev-shared/ui/checkbox/CheckboxAngular.Module';
import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { FormGroupAngularModule } from 'rev-shared/ui/formGroup/FormGroupAngular.Module';
import { ValidationAngularModule } from 'rev-shared/ui/validation/ValidationAngular.Module';
import { ValidationModule } from 'rev-shared/util/directives/form/validation/Validation.AngularModule';
import { VbNgSelectWrapModule } from 'rev-shared/ui/ng-select/VbNgSelectWrap.Module';
import { VbUiDatePickerModule } from 'rev-shared/ui/datePicker/VbUiDatePicker.Module';

const components = [VbVideoExpirationOptionsComponent];

@NgModule({
	id: 'VBrick.Portal.Media.Videos.VideoExpirationOptions',
	declarations: components,
	exports: components,
	imports: [
		ButtonsAngularModule,
		CheckboxAngularModule,
		CommonModule,
		DateAngularModule,
		FormGroupAngularModule,
		FormsModule,
		NgSelectModule,
		TranslateModule,
		ValidationAngularModule,
		ValidationModule,
		VbNgSelectWrapModule,
		VbUiDatePickerModule
	]
})

export class VbVideoExpirationOptionsModule {}
