import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { UploadMenuModule } from 'rev-portal/navigation/uploadMenu/UploadMenu.Module';

import { MediaUploadBtnComponent } from './MediaUploadBtn.Component';

@NgModule({
	imports: [
		CommonModule,
		BsDropdownModule,
		UploadMenuModule
	],
	declarations: [
		MediaUploadBtnComponent
	],
	exports: [
		MediaUploadBtnComponent
	]
})
export class MediaUploadBtnModule {}
