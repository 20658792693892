import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

import { ITemplate, ITemplateMenuService } from './TemplateMenu.Component';

import styles from './TemplateMenu.module.less';

const TypeheadDelayMs = 500;

@Component({
	selector: 'template-picker',
	templateUrl: './TemplatePicker.Component.html'
})
export class TemplatePickerComponent implements OnInit {
	@Input() public additionalFilters: (t: ITemplate) => boolean = null;
	@Input() public templatesService: ITemplateMenuService;
	@Output() public onTemplateSelected: EventEmitter<any> = new EventEmitter();
	@Output() public closeMenu: EventEmitter<any> = new EventEmitter();

	public get noTemplates(): boolean {
		return this.templates && this.templates.length === 0;
	}

	public readonly styles = styles;
	public readonly typeaheadDelayMs = TypeheadDelayMs;

	public selectedTemplate: ITemplate;
	public template: ITemplate;
	public templates: ITemplate[] = [];

	public ngOnInit(): void {
		this.loadTemplates();
	}

	public loadTemplates(): Promise<any> {
		return this.templatesService.getTemplates()
			.then(templates => {
				if (this.additionalFilters) {
					templates = templates.filter(this.additionalFilters);
				}
				this.templates = templates;
			})
			.catch(e => {
				console.error('Unable to load templates', e);
			});
	}

	public clearSelectedTemplate(): void {
		this.selectedTemplate = null;
	}

	public onSelect(template): void {
		this.selectedTemplate = template;
	}

	public onSubmit(): void {
		if(this.selectedTemplate) {
			this.onTemplateSelected.emit(this.selectedTemplate.id);
		}
	}

	public close(): void {
		this.closeMenu.emit();
	}
}
