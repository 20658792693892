import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';

import { UserNavMenuComponent } from './UserNavMenu.Component';

const components = [
	UserNavMenuComponent
];

@NgModule({
	declarations: [
		components
	],
	imports: [
		CommonModule,
		TranslateModule,
		UIRouterModule
	],
	exports: [
		components
	]
})

export class UserNavMenuModule {}
