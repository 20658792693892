import {
	Component,
	HostBinding,
	Input
} from '@angular/core';

import { VideoSelectionModelService } from '../search/bulkEdit/VideoSelectionModel.Service';
import { VbMediaTableAngularComponent } from './VbMediaTableAngular.Component';

@Component({
	selector: 'vb-media-table-select-column-header',
	template: `
		<button class="btn btn-checkbox"
			(click)="videoSelectionModel.toggleSelectAll()"
			role="checkbox"
			[attr.aria-checked]="videoSelectionModel?.isSelectAll"
			[attr.aria-label]="ariaLabel"
			[ngClass]="{active: videoSelectionModel?.isSelectAll}">
			<span class="glyphicons ok_2"></span>
		</button>`,
	host: {
		'[hidden]': '!(parent && parent?.columns[field] >= 0)',
		'[style.order]': 'parent && parent?.columns[field]'
	}
})
export class VbMediaTableSelectColumnHeaderAngularComponent {
	@Input() public field: string;
	@Input() public isMobileHidden: boolean;
	@Input() public mobileAlign: string;
	@Input() public videoSelectionModel: VideoSelectionModelService;
	@Input() public ariaLabel: string;
	@HostBinding('class') public classes: string = 'table-cell file-list-header-col';

	constructor(
		public parent: VbMediaTableAngularComponent
	) {
	}

	public ngOnInit(): void {
		if (this.mobileAlign) {
			this.classes = `${this.classes} mobile-align-${this.mobileAlign}`;
		}
		if (this.isMobileHidden) {
			this.classes = `${this.classes} mobile-hidden`;
		}
	}
}
