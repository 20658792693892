import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { IMediaFeatures } from 'rev-shared/media/IMediaFeatures';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { GoLiveFormComponent } from './GoLiveForm.Component';

import styles from './GoLive.module.less';

@Component({
	selector: 'go-live',
	templateUrl: './GoLive.Component.html'
})
export class GoLiveComponent implements OnInit {
	@Input() public alwaysThemeAccent: boolean;
	@Input() public bgNone: boolean;
	@Input() public dropdownCssClass: string;
	@Input() public iconCssClass: string;
	@Input() public showRemove: string;
	@Input() public headerType: string;

	@ViewChild(GoLiveFormComponent)
	public form: GoLiveFormComponent;

	public readonly styles = styles;
	public mediaFeatures: IMediaFeatures;

	constructor(
		private mediaFetaures: MediaFeaturesService
	) {}

	public ngOnInit(): void {
		this.mediaFetaures.getFeatures()
			.then(features => this.mediaFeatures = features);
	}

	public resetForm(): void {
		this.form.initialize();
	}
}
