<section>
	<header [ngClass]="styles.header">
		<branding-logo></branding-logo>
	</header>

	<main [ngClass]="styles.main">
		<section [ngClass]="styles.downloadWrapper">
			<a [ngClass]="styles.downloadLink" [href]="downloadUrl" download>
				<span class="padding-right-15 util-underline">{{ 'DownloadDetails_ClickHere' | translate }}</span>
				<span class="glyphicons download_alt" [ngClass]="styles.downloadIcon"></span>
			</a>
		</section>
	</main>
</section>
