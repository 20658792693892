import {
	Component,
	Input,
	OnChanges,
	SimpleChanges
} from '@angular/core';


import { IRules } from 'rev-shared/ui/css/CssRules.Contract';
import { VbFlickityCarouselComponent } from 'rev-shared/ui/flickityCarousel/VbFlickityCarousel.AngularComponent';

import { BrandingSettings } from 'rev-portal/branding/BrandingSettings.Contract';

import './featured-video-thumbnail-carousel.less';

@Component({
	selector: 'featured-video-thumbnail-carousel',
	template: `
		<ng-template [vbCssRules]="themeStyleOverrides"></ng-template>

		<vb-flickity-carousel
			[flickityConfig]="flickityConfig"
			(onReady)="onCarouselReady($event.carousel)">
			<div
				class="carousel-cell"
				*ngFor="let video of videos">
				<featured-video-thumbnail
					align-title-left
					[lazyLoad]="true"
					[video]="video">
				</featured-video-thumbnail>
			</div>
		</vb-flickity-carousel>
	`
})
export class FeaturedVideoThumbnailCarouselComponent implements OnChanges {
	@Input() public brandingSettings: BrandingSettings;
	@Input() public videos: any[];

	public themeStyleOverrides: IRules;

	public flickityConfig: any = {
		bgLazyLoad: 1, // +1 adjacent to current
		imagesLoaded: true,
		setGallerySize: false,
		wrapAround: true
	};

	public ngOnChanges(changes: SimpleChanges): void {
		if(changes.brandingSettings && this.brandingSettings) {
			const accentColor = this.brandingSettings.themeSettings.accentColor;
			const accentFontColor = this.brandingSettings.themeSettings.accentFontColor;
			this.themeStyleOverrides = {
				'featured-video-thumbnail-carousel .dot': {
					'border-color': accentFontColor
				},
				[`featured-video-thumbnail-carousel .dot.is-selected,
				featured-video-thumbnail-carousel .flickity-prev-next-button:hover`]: {
					'background-color': accentColor
				},
				'featured-video-thumbnail-carousel .arrow': {
					fill: accentFontColor
				}
			};

			this.flickityConfig = {
				...this.flickityConfig,
				autoPlay: this.brandingSettings.featuredVideosSettings.autoscrollDelay * 1000
			};
		}
	}

	public onCarouselReady(carousel: VbFlickityCarouselComponent): void {
		//workaround for first bg lazy loading not being triggered
		window.setTimeout(() => carousel.select(0));
	}
}
