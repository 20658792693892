import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';

import { IRules } from 'rev-shared/ui/css/CssRules.Contract';
import { MaxWidthSmallMediaQuery } from 'rev-shared/ui/size/Size.Constants';

import styles from './vb-accent-header.module.less';

@Component({
	selector: 'vb-ui-accent-header',

	template: `
		<ng-template [vbCssRules]="cssRules"></ng-template>
		<header
			class="theme-accent-border"
			[ngClass]="styles.header"
			layout="row"
			flex-align="end"
			flex-justify="space">
			<h1
				class="theme-accent-txt theme-accent-bg"
				[ngClass]="styles.headerText"
				[ngStyle]="{
					width: headerWidth
				}">
				{{ headerText }}
			</h1>
			<div
				class="padding-bottom-5"
				flex-align-self="center"
				*ngIf="hasActions">
				<ng-content></ng-content>
			</div>
		</header>
	`
})
export class VbUiAccentHeaderComponent implements OnChanges {
	@Input() public headerText: string;
	@Input() public headerWidth: string;
	@Input() public headerMobileWidth: string;
	@Input() public hasActions: boolean;

	public cssRules: IRules;
	public readonly styles = styles;

	public ngOnChanges(changes: SimpleChanges): void {
		if(changes.headerMobileWidth && this.headerMobileWidth) {
			this.cssRules = {
				[`@media ${MaxWidthSmallMediaQuery}`]: {
					[styles.headerText]: {
						width: `${this.headerMobileWidth} !important;`
					}
				}
			};
		}
	}
}

