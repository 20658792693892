import {
	Component,
	Input,
	OnInit,
	ViewChild
} from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { ITemplateDialog } from './ITemplateDialog';

import styles from './TemplateDialog.module.less';
import { isPushError } from 'rev-shared/push/PushService';
import { NgModel } from '@angular/forms';

export const TemplateDialogName = 'templateDialog';

@Component({
	selector: 'template-dialog',
	templateUrl: './TemplateDialog.Component.html'
})

export class TemplateDialogComponent {
	@Input() public templateInfo: ITemplateDialog;

	public readonly styles = styles;

	public isProcessing: boolean;
	public hasTemplateSaved: boolean;
	@ViewChild(NgModel, { static: true }) public templateName: NgModel;

	constructor(
		private bsModalRef: BsModalRef
	) {

	}

	public closePopup(): void {
		this.bsModalRef.hide();
	}

	public submit(): void {
		this.saveWebcastTemplate();
	}

	private saveWebcastTemplate(): void {
		this.isProcessing = true;

		this.templateInfo.saveFunc(this.templateInfo.name)
			.then(() => {
				this.hasTemplateSaved = true;
			})
			.catch(error => {
				if(isPushError(error) && error.hasIssue('TemplateNameInUse')) {
					this.templateName.control.setErrors({ duplicateName: true });
				}
			})
			.finally(() => this.isProcessing = false);
	}
}
