import {
	Component,
	Input,
	OnInit
} from '@angular/core';

import './team.less';

@Component({
	selector: 'teams-list',
	templateUrl: './TeamsList.Component.html'
})
export class TeamsListComponent implements OnInit {
	@Input() public isCreateTeamAccess: boolean;
	@Input() public teams: any[];

	public filterTerm: string;
	public filteredTeams: any[];

	public ngOnInit(): void {
		this.filteredTeams = this.teams.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1: -1);
	}

	public getTeamId(index: number, team: any): string {
		return team.id;
	}

	public filterTeamsList(): void {
		if (this.filterTerm?.length >= 2) {
			this.filteredTeams = this.teams.filter(team => team.name.toLowerCase().indexOf(this.filterTerm.toLowerCase()) >= 0);
		} else {
			this.filteredTeams = this.teams;
		}
	}
}
