import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';

import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';

import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';

const states: IVbNg2StateDeclaration[] = [
	{
		name: 'portal.debug.**',
		url: '/debug',
		loadChildren: () => {
			return import(/* webpackChunkName: 'debugModule' */ 'rev-portal/debugModule/Debug.Module')
				.then(dbg => dbg.DebugModule);
		}
	}
];

@NgModule({
	imports: [
		SpinnerAngularModule,
		UIRouterModule.forChild({
			states
		})
	]
})
export class DebugLoaderModule {
}
