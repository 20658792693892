import { Component, Input } from '@angular/core';

import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';

import styles from './unlisted-video-indicator.module.less';

@Component({
	selector: 'unlisted-video-indicator',
	template: `<div *ngIf="showUnlistedIndicator"
		[ngClass]="[
			styles.unlistedVideoStatus,
			showOnlyIcon ? styles.onlyIconDisplay : ''
		]">
		<span
			class="vb-icon vb-icon-unlisted-video"
			[title]="'Unlisted' | translate"></span>
		<span
			class="util-uppercase"
			[hidden]="showOnlyIcon">
			{{'Unlisted' | translate}}
		</span>
	</div>`
})
export class UnlistedVideoIndicatorComponent {
	@Input() public isVideoUnlisted: boolean;
	@Input() public hasMediaEditAuth: boolean;
	@Input() public showOnlyIcon: boolean;

	public isUnlistedVideoEnabled: boolean;
	public readonly styles = styles;

	constructor(
		MediaFeatures: MediaFeaturesService
	) {
		this.isUnlistedVideoEnabled = MediaFeatures.accountFeatures.enableUnlisted;
	}

	public get showUnlistedIndicator(): boolean {
		return this.hasMediaEditAuth
			&& this.isUnlistedVideoEnabled
			&& this.isVideoUnlisted;
	}
}
