export enum EncodingType {
	H264 = 'H264',
	HLS = 'HLS',
	HDS = 'HDS',
	H264TS = 'H264TS',
	MPEG4 = 'Mpeg4',
	MPEG2 = 'Mpeg2',
	WM = 'WM'
}

export const EncodingTypes: string[] = Object.values(EncodingType);
