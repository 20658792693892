
import { Injectable, Optional } from '@angular/core';
import { Subscription } from 'rxjs';

import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { TeamListingsService } from 'rev-portal/media/teams/TeamListings.Service';

@Injectable({
	providedIn: 'root'
})
export class MediaMenuService {
	public tlsSub: Subscription;
	public isFirstFetchComplete: boolean = false;
	public isFetching: boolean = false;
	public isCategoriesEnabled: boolean;
	public isMySubscriptionEnabled: boolean;

	public readonly maxVisibleTeams: number = 12;

	constructor(
		private MediaFeatures: MediaFeaturesService,
		@Optional() public TeamListingsService: TeamListingsService
	) { }

	public initiateTLSSubscription(): void {
		this.tlsSub = this.TeamListingsService.dirty$.subscribe(() => {
			this.isFirstFetchComplete = false;
			this.isFetching = false;
		});
	}

	public unsubscribeTLSSubscription(): void {
		this.tlsSub?.unsubscribe();
	}

	public get hasTeams(): boolean {
		return this.TeamListingsService.teamData.length > 0;
	}

	public fetchAllData(): void {
		if (!this.isFirstFetchComplete && !this.isFetching) {
			this.isFetching = true;
			Promise.all([
				this.fetchMyTeams(),
				this.fetchMediaFeatures()
			]).then(() => {
				this.isFirstFetchComplete = true;
				this.isFetching = false;
			});
		}
	}

	public fetchMediaFeatures(): Promise<any> {
		return this.MediaFeatures.getFeatures()
			.then(feature => {
				this.isCategoriesEnabled = feature.enableCategories;
				this.isMySubscriptionEnabled = feature.enableContentNotifications;
			});
	}

	public fetchMyTeams(): Promise<any> {
		return this.TeamListingsService.listTeams();
	}
}
