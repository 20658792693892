import {
	Component,
	Input,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	ElementRef
} from '@angular/core';
import { map } from 'rxjs/operators';


import { MaxWidthSmallMediaQuery } from 'rev-shared/ui/size/Size.Constants';
import { VbFlickityCarouselComponent } from 'rev-shared/ui/flickityCarousel/VbFlickityCarousel.AngularComponent';

import { ThemeService } from 'rev-portal/branding/Theme.Service';

import './dashboard-carousel.less';
import styles from './dashboard-carousel.module.less';

const lazyLoadAttr = 'data-flickity-lazyload';

@Component({
	selector: 'dashboard-carousel',
	templateUrl: './DashboardCarousel.Component.html',
})

export class DashboardCarouselComponent implements OnChanges, OnDestroy {
	@Input() public headerSref: string;
	@Input() public headerSrefParams: any;
	@Input() public headerText: string;
	@Input() public items: any[];

	public readonly styles = styles;

	private carousel: VbFlickityCarouselComponent;
	public hasItems: boolean;
	private mobileMediaQuery: MediaQueryList;
	private mobileMediaQueryListener: () => void;

	public cssRules$ = this.ThemeService.brandingSettings$.pipe(map(settings => {
		const themeSettings = settings?.themeSettings || {} as any;
		return {
			'dashboard-carousel .flickity-prev-next-button:hover': {
				'background-color': themeSettings.accentColor + ' !important'
			},
			'dashboard-carousel .flickity-prev-next-button:hover .arrow': {
				fill: themeSettings.accentFontColor + ' !important'
			},
			'carousel-header aside a:hover': {
				color: themeSettings.primaryFontColor + ' !important',
				'text-decoration': 'none'
			},
			'dashboard-carousel .flickity-prev-next-button.previous, dashboard-carousel .flickity-prev-next-button.next' : {
				'box-shadow': '0 0 5px {{ $ctrl.ThemeService.brandingSettings.themeSettings.primaryFontColor }};'
			},

			'dashboard-carousel .flickity-prev-next-button .arrow': {
				fill: themeSettings.primaryFontColor
			},

			'dashboard-carousel .flickity-prev-next-button.no-svg': {
				color: themeSettings.primaryFontColor
			}
		};
	}));

	public flickityConfig = {
		cellAlign: 'left',
		draggable: false,
		groupCells: true,
		lazyLoad: true,
		pageDots: false,
		wrapAround: true
	};

	constructor(
		private el: ElementRef,
		private ThemeService: ThemeService
	) { }

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.items?.currentValue) {
			this.hasItems = this.items.length > 0;
		}
	}

	public ngOnDestroy(): void {
		this.removeMobileMediaQueryListener();
	}

	public get themeSettings() {
		return this.ThemeService.brandingSettings.themeSettings;
	}

	private onMobileMediaQueryChange(): void {
		if (this.mobileMediaQuery.matches) {

			// set the src on all of the images still having the lazy load attribute
			(this.el.nativeElement as HTMLElement)
				.querySelectorAll(`[${lazyLoadAttr}]`)
				.forEach((img: HTMLImageElement) => {
					img.src = img.getAttribute(lazyLoadAttr);
					img.removeAttribute(lazyLoadAttr);
				});

			//only needs to be executed once
			this.removeMobileMediaQueryListener();
		}
	}

	public onCarouselReady(carousel: VbFlickityCarouselComponent): void {
		// listen for the mobile media query
		this.mobileMediaQuery = window.matchMedia(MaxWidthSmallMediaQuery);
		this.mobileMediaQueryListener = () => this.onMobileMediaQueryChange();
		this.mobileMediaQuery.addListener(this.mobileMediaQueryListener);

		// initial run since you won't get an event on init
		this.onMobileMediaQueryChange();
	}

	private removeMobileMediaQueryListener(): void {
		if (this.mobileMediaQuery) {
			this.mobileMediaQuery.removeListener(this.mobileMediaQueryListener);
		}
	}
}
