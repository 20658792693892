import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';
import { PushService } from 'rev-shared/push/PushService';
import { INotification } from './Notification.Contract';
import { NotificationPriority } from './NotificationPriority';

interface INotificationsResponse {
	notifications: INotification[];
}

@Injectable()
export class NotificationService {
	constructor(
		private DateParsers: DateParsersService,
		private http: HttpClient,
		private PushService: PushService,
	) {}

	public getNotifications(userId: string): Promise<INotification[]> {
		return lastValueFrom(this.http.get<any>(`/network/users/${userId}/inbox`))
			.then(notifications => {
				return (notifications.notifications || [])
					.map(n => this.shapeNotificationObj(n))
					.sort((a, b) => (+b.notificationDate) - (+a.notificationDate));
			});
	}

	public markAsRead(userId: string, notificationId: string): Promise<void> {
		return this.PushService.dispatchCommand('network:MarkNotificationAsRead', { userId, notificationId });
	}

	public markAllAsRead(userId: string): Promise<void> {
		return this.PushService.dispatchCommand('network:MarkAllNotificationsAsRead', { userId });
	}

	public shapeNotificationObj(notification: any): INotification {
		return {
			id: notification.notificationId || notification.id,
			notificationType: notification.notificationType,
			content: JSON.parse(notification.content || '{}'),
			priority: notification.priority,
			priorityNumber: this.getNotificationPriorityNumber(notification),
			markedAsRead: notification.markedAsRead,
			notificationDate: this.DateParsers.parseUTCDate(notification.notificationDate)
		};
	}

	private getNotificationPriorityNumber(notification: INotification): number {
		const p = notification.priority;

		return p === NotificationPriority.Low ? 0 :
			p === NotificationPriority.High ? 1 : 2;
	}
}
