import { Component, Input } from '@angular/core';

import { BrandingHeaderType } from 'rev-portal/branding/BrandingHeaderType';

import { MediaMenuService } from './MediaMenu.Service';

import './media-menu-dropdown.less';

@Component({
	selector: 'media-menu-dropdown',
	templateUrl: './MediaMenuDropdown.Component.html'
})

export class MediaMenuDropdownComponent {
	@Input() public currentTheme: string;

	constructor(
		private MediaMenuService: MediaMenuService
	) { }

	public onToggleClick(): void {
		this.MediaMenuService.fetchAllData();
	}

	public get isTwoTierTheme() {
		return this.currentTheme === BrandingHeaderType.TwoTier;
	}
}
