import {
	Component,
	EventEmitter,
	Input,
	Output,
	SimpleChanges
} from '@angular/core';
import { throttle } from 'underscore';

import { IUserSubscriptionType } from './IUserSubscriptionType';

import styles from './VbUserSubscription.module.less';

@Component({
	selector: 'vb-user-subscription-select',
	templateUrl: './VbUserSubscriptionSelect.Component.html'
})
export class VbUserSubscriptionSelectComponent {
	@Input() public subscriptionTypes: IUserSubscriptionType[];

	@Output() public subscriptionChanged: EventEmitter<IUserSubscriptionType> = new EventEmitter<IUserSubscriptionType>();
	public readonly styles = styles;

	public multiSubscriptions: boolean;

	private throttledDispatchEvent = throttle((subscription: IUserSubscriptionType) => this.subscriptionChanged.emit(subscription), 300);

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.subscriptionTypes && this.subscriptionTypes?.length) {
			this.multiSubscriptions = this.subscriptionTypes.length > 1;
		}
	}

	public onSubscriptionChange(subscription: IUserSubscriptionType) {
		this.throttledDispatchEvent(subscription);
	}

	public get isSubscribed(): boolean {
		return this.subscriptionTypes.some(sub => sub.isSubscribed);
	}
}
