import { Injectable } from '@angular/core';

import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';

@Injectable({
	providedIn: 'root'
})
export class UserAuthorizationService {

	constructor(
		private SecurityContext: SecurityContextService
	) { }

	public hasContentCreatorRights(): boolean {
		return this.SecurityContext.checkAuthorization('media.add') || this.SecurityContext.checkAuthorization('events.add');
	}

}
