import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { DateUtil } from 'rev-shared/date/DateUtil';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

@Injectable({
	providedIn: 'root'
})
export class ScheduledEventService {

	private readonly events: { [id: string]: any } = {};

	constructor(
		private DateParsers: DateParsersService,
		private HttpClient: HttpClient
	) {
	}

	public getScheduledEvents(accountId: string, fromDate?: Date, toDate?: Date): Promise<any[]> {
		return lastValueFrom(this.HttpClient.get(`/scheduled-events/accounts/${accountId}`, {
			params: {
				fromDate: fromDate?.toISOString(),
				toDate: toDate?.toISOString()
			}
		})).then((result: any) => (result.scheduledEvents || []).map(event => {
			const startDate: Date = this.DateParsers.parseUTCDate(event.startDate);
			const endDate: Date = this.DateParsers.parseUTCDate(event.endDate);
			const preProdDuration = event.canJoinPreProduction && event.preProductionDuration;

			return {
				...event,
				startDate,
				endDate,
				preProduction: preProdDuration && {
					durationMs: this.DateParsers.parseTimespan(preProdDuration)
				}
			};
		}));
	}

	public getUpcomingEvents(accountId: string): Promise<any[]> {
		let promise = this.events[accountId];
		if (promise) {
			return promise;
		}
		const fromDate = new Date();
		const toDate = DateUtil.addMonths(fromDate, 1);
		promise = this.getScheduledEvents(accountId, fromDate, toDate);

		this.events[accountId] = promise;
		window.setTimeout(() => this.events[accountId] = undefined, 60000);
		return promise;
	}
}
