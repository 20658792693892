import { Injectable } from '@angular/core';

import { chain, groupBy, sortBy } from 'underscore';
import { Observable, empty } from 'rxjs';
import { switchMap, startWith } from 'rxjs/operators';

import { DateUtil } from 'rev-shared/date/DateUtil';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { PushBus } from 'rev-shared/push/PushBus.Service';

import { INotification } from './Notification.Contract';
import { NotificationPriority } from './NotificationPriority';
import { NotificationService } from './Notification.Service';

const MaxNumberOfNotifications = 100;

export interface INotificationGroup {
	date: Date;
	offset: number;
	notifications: INotification[];
}

@Injectable()
export class UserNotificationsService {
	public notifications: INotification[] = [];
	public unreadNotifications: INotification[] = [];
	public lowNotifications: INotificationGroup[] = [];
	public highNotifications: INotification[] = [];
	public $promise: Promise<INotification[]>;

	constructor(
		private NotificationService: NotificationService,
		private PushBus: PushBus,
		private UserContext: UserContextService
	) {
		this.registerNotificationMessageHandlers()
			.subscribe();
	}

	public refresh(): Promise<INotification[]> {
		this.notifications = [];
		const userId = this.UserContext.isUserAuthenticated() &&
			this.UserContext.getUser().id;

		if (userId) {
			this.$promise = this.NotificationService.getNotifications(userId)
				.then(notifications => {
					this.notifications = notifications;
					this.sortNotifications();
					return notifications;
				});
		}
		else {
			this.$promise = Promise.resolve([]);
		}

		return this.$promise;
	}

	public markAsRead(notificationId: string): void {
		const userId = this.UserContext.getUser().id;
		this.NotificationService.markAsRead(userId, notificationId);
		this.markRead(this.getNotification(notificationId));
	}

	public markAllAsRead(): void {
		const userId = this.UserContext.getUser().id;
		this.NotificationService.markAllAsRead(userId);
		this.notifications.forEach(n => this.markRead(n));
	}

	private markRead(notification: INotification): void {
		if(notification){
			notification.markedAsRead = true;
		}
	}

	private getNotification(id: string): INotification {
		return this.notifications.find(n => n.id === id);
	}

	private registerNotificationMessageHandlers(): Observable<any> {
		return this.UserContext.userIdChanged$.pipe(
			startWith(null),
			switchMap(() => {
				this.refresh();
				const userId = this.UserContext.isUserAuthenticated() &&
					this.UserContext.getUser().id;

				if(!userId) {
					return empty();
				}

				return this.PushBus.getObservable(userId, 'User', {


					NotificationDelivered: notification => {
						if (this.notifications.length >= MaxNumberOfNotifications) {
							//oldest items go out first!
							this.notifications.shift();
						}

						this.notifications.push(this.NotificationService.shapeNotificationObj(notification));
						this.sortNotifications();

						return empty();
					},

					NotificationMarkedAsRead: data => {
						this.markRead(this.getNotification(data.notificationId));
						this.sortNotifications();
						return empty();
					},

					AllNotificationsMarkedAsRead: () => {
						this.notifications.forEach(n => this.markRead(n));
						this.sortNotifications();
						return empty();
					}
				});
			})
		);
	}

	private sortNotifications(): void {
		const today = DateUtil.getToday();
		this.highNotifications = [];
		const lowNotifications = [];

		this.notifications.forEach(n => {
			if(n.priority === NotificationPriority.Low){
				lowNotifications.push(n);
			}
			else{
				this.highNotifications.push(n);
			}
		});

		const lowNotificationsByDay = groupBy(lowNotifications, n => {
			return DateUtil.daysBetween(today, DateUtil.getStartOfDay(n.notificationDate));
		});

		this.lowNotifications = Object.keys(lowNotificationsByDay).map(offset => {
			const group = lowNotificationsByDay[offset];
			return {
				date: DateUtil.addDays(today, -offset),
				offset: +offset,
				notifications: group
			};
		});

		this.lowNotifications = sortBy(this.lowNotifications, 'offset');

		this.unreadNotifications = chain(this.notifications)
			.filter(n => !n.markedAsRead)
			.sortBy(n => -n.notificationDate)
			.sortBy(n => -n.priorityNumber)
			.value() as any;
	}
}
