import { Component } from '@angular/core';

import styles from './no-carousel-results.module.less';

@Component({
	selector: 'no-carousel-results',
	template: `
		<section [ngClass]="styles.sectionWrapper">
			<div *ngFor="let number of [0,1,2,3,4]"
				[ngClass]="styles.tileWrapper">
				<div [ngClass]="styles.tileSmall">
					<div [ngClass]="styles.fixedRatio" class="fixed-ratio-wrapper">
						<div class="fixed-ratio">
							<div [ngClass]="styles.greekedImage" class="fixed-aspect-item"></div>
						</div>
					</div>
					<div [ngClass]="styles.infoContainer">
						<h4><span [ngClass]="styles.greekedText"></span></h4>
						<h4><span [ngClass]="styles.greekedText"></span></h4>
					</div>
				</div>
			</div>
			<div [ngClass]="styles.noItems">
				<h4 class="theme-primary-txt">{{ 'UI_NoResultsFound' | translate }}</h4>
			</div>
		</section>
	`
})
export class NoCarouselResultsComponent {
	public readonly styles = styles;
}
