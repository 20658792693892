import {
	Component,
	HostBinding,
	Input
} from '@angular/core';

import { VbMediaTableAngularComponent } from './VbMediaTableAngular.Component';

@Component({
	selector: 'vb-media-table-column',
	template: '<ng-content></ng-content>',
	host: {
		'[hidden]': '!(parent && parent?.columns[field] >= 0)',
		'[style.order]': 'parent && parent?.columns[field]'
	}
})
export class VbMediaTableColumnAngularComponent {
	@Input() public field: string;
	@Input() public isMobileHidden: boolean;
	@Input() public mobileAlign: string;
	@HostBinding('class') public classes: string = 'table-cell';

	constructor(
		public parent: VbMediaTableAngularComponent
	) {
	}

	public ngOnInit(): void {
		if (this.mobileAlign) {
			this.classes = `${this.classes} mobile-align-${this.mobileAlign}`;
		}
		if (this.isMobileHidden) {
			this.classes = `${this.classes} mobile-hidden`;
		}
	}
}
