import { Transition } from '@uirouter/angular';

import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';

import { TeamService } from './Team.Service';
import { ManageTeamComponent } from './ManageTeam.Component';

export const sharedTeamState = {
	component: ManageTeamComponent,
	resolve: [
		{
			token: 'teamId',
			deps: [Transition],
			resolveFn: ($transition$: Transition) => $transition$.params().teamId
		},
		{
			token: 'portalTeamAdminAccess',
			deps: [SecurityContextService],
			resolveFn: (SecurityContext: SecurityContextService) => SecurityContext.checkAuthorization('admin.media.teams')
		},
		{
			token: 'team',
			deps: [Transition, 'portalTeamAdminAccess', 'teamId', SecurityContextService, TeamService],
			resolveFn: (
				$transition$: Transition,
				portalTeamAdminAccess: boolean,
				teamId: string,
				SecurityContext: SecurityContextService,
				TeamService: TeamService) => {
				return new Promise((resolve, reject) => {
					if (teamId) {
						return TeamService.loadTeam(teamId)
							.then(() => resolve(null));
					}
					const isAuthorizedToAddTeam: boolean = portalTeamAdminAccess
						|| SecurityContext.checkAuthorization('teams.add');

					if (!isAuthorizedToAddTeam) {
						return reject({ status: 401 });
					}
					return resolve(null);
				});
			}
		}
	],
	onExit(transition: Transition): void {
		const injector = transition.injector();
		injector.getAsync<TeamService>(TeamService)
			.then(teamService => teamService.unloadTeam(transition.params().teamId));
	}
};
