
import { Component, Input } from '@angular/core';

import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';

import './user-nav-menu.less';

@Component({
	selector: 'user-nav-menu',
	templateUrl: './UserNavMenu.Component.html'
})
export class UserNavMenuComponent {
	@Input() public user: string;
	@Input() public readonlyUserProfile: boolean;
	@Input() public showManageSubscriptions: boolean;
	@Input() public isBaseHeaderBgColor: boolean;
}
