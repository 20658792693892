import { ResolvableLiteral, Transition, UIRouter } from '@uirouter/angular';

import { EntryPointStartup, IEntryPointStartup } from 'rev-shared/entryPoint/EntryPointStartup.Provider';
import { LoginComponent } from 'rev-shared/security/components/Login.Component';
import { DASHBOARD_STATE } from 'rev-shared/bootstrap/AppStateNames';
import { PasswordResetComponent } from 'rev-shared/security/components/PasswordReset.Component';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';
import { SsoErrorDisplayComponent } from 'rev-shared/security/components/SsoErrorDisplay.Component';
import { UserAgreementComponent } from 'rev-shared/security/components/UserAgreement.Component';
import { UserConfirmationComponent } from 'rev-shared/security/components/UserConfirmation.Component';
import { getCommonSecurityStatesNg2 } from 'rev-shared/security/components/SecurityComponentsState.Config';

import { PortalComponent } from './Portal.Component';
import { getPortalBaseStates } from './PortalBaseState.Config';

export function configureRouter(uiRouter: UIRouter): void {
	uiRouter.urlService.rules.otherwise({ state:  DASHBOARD_STATE });
}


const initializationResolve: ResolvableLiteral[] = [
	{
		token: 'PortalStartupResolve',
		deps: [
			EntryPointStartup
		],
		resolveFn: (EntryPointStartup: IEntryPointStartup) => EntryPointStartup.$promise
	},
	{
		token: 'SecurityContextStartup',
		deps: [
			SecurityContextService
		],
		resolveFn: (SecurityContext: SecurityContextService) => SecurityContext.$promise
	}
];



export const states = [
	...getCommonSecurityStatesNg2(initializationResolve, LoginComponent, UserAgreementComponent),
	...getPortalBaseStates(PortalComponent, true, initializationResolve),
	{
		name: 'sso-invalid-credentials',
		url: '/sso-invalid-credentials',
		component: SsoErrorDisplayComponent,
		allowAllAccess: true,
		resolve: [
			...initializationResolve,
			{
				token: 'errorMessageKeys',
				resolveFn: () => {
					return [
						'Login_InvalidCredentials',
						'Login_SsoMismatchError'
					];
				}
			}
		]
	},
	{
		name: 'sso-invalid-license',
		url: '/sso-invalid-license',
		component: SsoErrorDisplayComponent,
		allowAllAccess: true,
		resolve: [
			...initializationResolve,
			{
				token: 'errorMessageKeys',
				resolveFn: () => {
					return [
						'Login_SsoInvalidLicense',
						'Login_SsoNoAvailableLicense'
					];
				}
			}
		]
	},
	{
		name: 'sso-invalid-login',
		url: '/sso-invalid-login',
		component: SsoErrorDisplayComponent,
		allowAllAccess: true,
		resolve: [
			...initializationResolve,
			{
				token: 'errorMessageKeys',
				resolveFn: () => {
					return [
						'Login_SsoInvalidLogin',
						'Login_SsoInvalidLoginMessage'
					];
				}
			}
		]
	},
	{
		name: 'user-confirmation',
		url: '/user-confirmation/:token',
		component: UserConfirmationComponent,
		allowAllAccess: true,
		resolve: [
			...initializationResolve,
			{
				token: 'token',
				deps: [
					Transition
				],
				resolveFn: (transition: Transition) => transition.params().token
			}
		]
	},
	{
		name: 'reset-password',
		url: '/reset-password/:token',
		component: PasswordResetComponent,
		allowAllAccess: true,
		resolve: [
			...initializationResolve,
			{
				token: 'token',
				deps: [
					Transition
				],
				resolveFn: (transition: Transition) => transition.params().token
			}
		]
	}
];
