import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';

import { BrandingAngularModule } from 'rev-portal/branding/BrandingAngular.Module';
import { DownloadDetailsComponent } from './DownloadDetails.Component';
import { states } from './StateDeclarations';

@NgModule({
	declarations: [
		DownloadDetailsComponent
	],
	imports: [
		BrandingAngularModule,
		CommonModule,
		TranslateModule,
		UIRouterModule.forChild({ states })
	]
})
export class DownloadDetailsModule {
}
