import {
	Component,
	Input,
	ElementRef,
	ViewChild,
	AfterViewInit
} from '@angular/core';

import './featured-video-thumbnail.less';

@Component({
	selector: 'featured-video-thumbnail',
	template: `
		<div class="thumbnail-bg"
			#thumbnailBg
			[attr.data-flickity-bg-lazyload]="lazyLoad ? video.thumbnailUri : undefined">
			<div class="thumbnail-gradient"></div>
		</div>
		<div class="thumbnail-content">
			<h1>
				<div class="overflow-container">
					{{video.title}}
				</div>
			</h1>
			<a
				class="btn btn-primary theme-accent"
				[attr.aria-label]="video.title + ' - ' + ('WatchNow' | translate)"
				uiSref="portal.video"
				[uiParams]="{ videoId: video.id}">
				{{'WatchNow' | translate }}

				<div class="circle theme-accent-font-border">
					<span class="glyphicons play"></span>
				</div>
			</a>
		</div>
	`
})
export class FeaturedVideoThumbnailComponent implements AfterViewInit {
	@Input() public lazyLoad: boolean;
	@Input() public video: any;

	@ViewChild('thumbnailBg') public backgroundRef: ElementRef;

	public ngAfterViewInit(): void {
		if(!this.lazyLoad && this.video) {
			const url = this.video.thumbnailUri;
			const el = this.backgroundRef.nativeElement as HTMLElement;
			el.style.backgroundImage = url ? `url(${url})` : '';
		}
	}
}
